import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import AboutThumb from "components/about-us/thumbnail/AboutThumbOne";
import thumb from '../../assets/img/about/about-2.jpg'

const AboutAuthenticStudio = () => {
    return (
        <div className="brook-about-area bg_color--1 ptb--120 ptb-md--80 ptb-sm--80">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="about-inner authentic-about pr--30">
                            <h6 className="heading heading-h6 theme-color wow move-up">WHO WE ARE</h6>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h3 heading-font line-height-1-5 wow move-up">
                            Cityscape Design Consultants is a premier architectural design consultancy specializing in creating luxurious and sustainable Architectural designs.
                            </h3>

                            <div className="bk-separator--40"/>
                            <p className="bk_pra font-20 line-height-1-6 dark-2 heading-font2 wow move-up">
                                With a keen focus on blending modern aesthetics with the natural beauty of the landscape, Cityscape Design Consultants 
                                crafts bespoke solutions that cater to the unique needs and preferences of their clients. 
                                Their expertise lies in designing structures that not only offer unparalleled comfort and elegance but also harmonize with the environment, ensuring minimal ecological impact. 
                                Whether it's a serene mountain retreat or a lavish hillside resort, Cityscape Design Consultants brings visionary ideas to life, transforming spaces into architectural masterpieces.
                                
                            </p>
                            <div className="bk-separator--65"/>

                            {/* <div className="view-more-btn heading-color heading-font font-18 font-700 theme-color wow move-up">
                                <a href={`${process.env.PUBLIC_URL + '/'}`}>
                                    <span>More about us</span>
                                    <span className="btn-arrow"/>
                                </a>
                            </div> */}
                        </div>
                    </Col>

                    <Col lg={6}>
                        <AboutThumb
                            thumb={thumb}
                            className="about-thumb text-center text-lg-right mt_md--40 mt_sm--40"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutAuthenticStudio;
