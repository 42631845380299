import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/HeaderEight";
import Breadcrumb from "components/breadcrumb/BreadcrumbSeven";
import Portfolios from "container/portfolio/pages/ThakurResidencePage";
import CityScapeFooter from 'container/footer/CityScapeFooter';

const ThakurResidencePortfolio = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Cityscape Design Consultants || Thakur Residence </title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-1">
                <Header/>
                <Breadcrumb
                    title="Thakur Residence"
                />
                <Portfolios wide={false} gutter={true}/>
                <CityScapeFooter />
            </LayoutDefault>
        </Fragment>
    );
};

export default ThakurResidencePortfolio;
